import React, { Component } from "react";
import { Row, Col, Form, Input, Button, Card } from "antd";
import styled from "styled-components";
import HomeImage from "../static/Home/home-img.jpg";
import ProductImage1 from "../static/Home/product-image-1.png";
import ProductImage2 from "../static/Home/bookshelf.jpg";
import Justin from "../static/Home/Justin.jpg";
import Johan from "../static/Home/Johan.jpg";
import ProductImage3 from "../static/Home/product-image-3.png";
import GalleryHome from "../static/Home/bookshelf.png";
import GalleryHomeBack from "../static/Home/bookcase.png";
import GalleryHomeBackMobile from "../static/Home/bookcase-mobile.png";
import { Modal as SuccessModal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import WingLeft from "../static/Home/wing-left.png";
import WingRight from "../static/Home/wing-right.png";
import Eifel from "../static/Home/eifel-img.png";
import LogoModal from "../static/Home/main-logo.png";
import EifelMobile from "../static/Home/eifel-img-mobile.png";
import HoriLine from "../static/Home/hori-line.svg";
import { RightOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";
import { CgArrowLongRight } from "react-icons/cg";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";

if (window.innerWidth <= 440 || window.innerHeight <= 440) {
  window.isPhone = true;
} else {
  window.isPhone = false;
}

const StyledInput = styled(Input)`
box-shadow:none !important;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding-left: 1.2em !important;
  font-variant: tabular-nums;
  list-style: none;
  -webkit-font-feature-settings: "tnum", "tnum";
  font-feature-settings: "tnum", "tnum";
  position: relative;
  height: 30px !important;
  padding: 4px 0px ;
  background-color: white!important;
  background-image: none;
  border: none;
  border-bottom: 1px solid black;
  border-radius: 0px;
  
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  color: black!important;

  :placeholder {
    color: lightgrey !important;
  }
  :hover {
    border-bottom: 1px solid black !important;
  }
  :active{
    border-bottom: 1px solid black !important;
  }
  :hover {
    border-bottom: 1px solid black !important;
  }
  :focus {
    border-bottom: 1px solid black !important;
  }
  @media only screen and (max-width: 600px) {
  width:100vw;
  margin:auto;
    }
  }
`;

const { Meta } = Card;
const Title = () => (
  <h1
    className="mulish-header"
    style={{
      marginTop: "5rem",
      fontWeight: 400,
      fontSize: 45,
      lineHeight: "1em",
      marginLeft: "0em",
      color: "black",
    }}
  >
    Geldenhuys Botha Inc.
    <br />
    Established on 4 August 2004
    <br />
    <a
      href="/#intro"
      style={{
        marginTop: "0em",
        fontWeight: 400,
        fontSize: 16,
        marginLeft: "0em",
        fontFamily: "Lato",
        color: "black",
        textDecoration: "underline",
      }}
    >
      Learn More
    </a>
  </h1>
);
const Image = () => (
  <img
    className="home-img"
    style={{
      margin: "auto",
      marginTop: "4em",
      textAlign: "center",
      width: "100vw",
    }}
    src={HomeImage}
  ></img>
);
const AboutHeader = () => (
  <div id="about">
    {/* <p
        className="Mulish"
        style={{
          fontWeight: 500,
          fontSize: "18px",
          marginBottom: "-0em",
          color: "rgb(214, 54, 41)",
        }}
      >
        <img
          className="hori-line"
          style={{ marginTop: "-0.2em" }}
          src={HoriLine}
        ></img>
        {"  "} About Us
      </p>
      <p
        className="Mulish-center"
        style={{
          fontWeight: 500,
          fontSize: "42px",
          marginBottom: "-0em",
          lineHeight: 1.3,
          paddingRight: "3em",
        }}
      >
        We aim to grow a business that is cost efficient and beneficial for our
        clientele.
      </p> */}
  </div>
);
const AboutParagraph = () => (
  <div id="intro">
    <div
      style={{
        backgroundColor: "#1B181A",
        borderRadius: "1em",
        padding: window.isPhone ? "2em" : "5em",
        textAlign: window.isPhone ? "left" : "",
      }}
    >
      <p
        className="Mulish-center"
        style={{
          color: "white",
          fontWeight: 500,
          fontSize: window.isPhone ? "25px" : "42px",
          marginBottom: "-0em",
          lineHeight: 1,
          paddingRight: "3em",
        }}
      >
        Our Legal Team -
        <br /> The Firm
      </p>
      <p
        className="Mulish"
        style={{
          fontSize: 16,
          marginTop: "1.5rem",
          color: "white",
          fontFamily: "Lato",
        }}
      >
        The firm was founded by P.J. Geldenhuys in 1998. Whereafter he served at
        the Department of Justice as State Prosecutor. State advocate and later
        as head of Civil Court framing for years.
        <br />
        <br />
        Mr. J.H. Botha joined the firm 2 years later as head of conveyancing
        together with Mrs. A.C. Buchling as conveyancer of the firm.
        <br /> <br />
        During 2011, Mr. J Steyn joined our team and elevated the litigation
        department to where it is today with innovation and determination for
        success.
      </p>{" "}
      <a href="/#about-us">
        <Button
          id="services"
          className="Mulish"
          style={{
            marginTop: "1em",
            fontSize: "14px",
            marginBottom: "-0em",
            color: "white",
            fontFamily: "Lato",
            borderRadius: "3em",
            width: "11em",
            height: "2.8em",
            border: "2px solid white",
            backgroundColor: "transparent",
          }}
        >
          Learn More
          {/* <CgArrowLongRight style={{ marginLeft: "0.5em",marginBottom:"-0.25em" }} /> */}
        </Button>
      </a>
    </div>
  </div>
);
const AboutHeader1 = () => (
  <div
    style={{
      paddingTop: "4em",
      width: window.isPhone ? "" : "38em",
      margin: "auto",
      textAlign: "left",
      paddingLeft: window.isPhone ? "2em" : "",
      paddingRight: window.isPhone ? "2em" : "",
    }}
  >
    <p
      className="Mulish"
      style={{
        fontWeight: 400,
        fontSize: window.isPhone ? "35px" : "50px",
        marginBottom: "0.5em",
        color: "black",
        letterSpacing: "0.14em",
        fontStyle: "italic",
      }}
    >
      About Us
    </p>
    <p
      className="Mulish-center"
      style={{
        fontWeight: 300,
        fontSize: "15px",
        marginBottom: "-0em",
        lineHeight: 1.3,
        paddingRight: "3em",
        fontFamily: "Lato",
      }}
    >
      <strong>
        Clients are the reason for our existence and deserve to be treated as
        such.
      </strong>
      <br />
      <br />
      With a dedicated approach to success and a reluctance to stall or
      postpone, we strive to deliver all the services needed to assist our
      valued clients. We do this by resolving any and all matters without delay.
    </p>
    <p
      className="Mulish"
      style={{
        fontSize: 15,
        fontWeight: 300,
        marginTop: "1.5rem",
        color: "black",
        fontFamily: "Lato",
      }}
    >
      <strong>We strive for:</strong>
      <br />
      <ul>
        <li>the development of our people to reach their full potential;</li>
        <li>the rewarding of excellent performance;</li>
        <li>
          to create and nurture a culture of trust, respect, openness, teamwork,
          integrity and loyalty in our workplace;
        </li>
        <li>to encourage creativity and innovation in our people;</li>
        <li>to employ current technology;</li>
        <li>
          to be the leading firm in conveyancing and litigation with all
          customers satisfied;
        </li>
        <li>
          We strive to become an asset to the community and provide our clients
          with the most professional service at all times.
        </li>
      </ul>
    </p>{" "}
  </div>
);
const AboutParagraph1 = () => (
  <div>
    <img
      className="products-img-1"
      style={{
        margin: "auto",
        // width: "50vw",
        height: "43em",
        textAlign: "center",
        objectFit: "contain",
      }}
      src={ProductImage2}
    ></img>
  </div>
);
const MeetTheTeam1 = () => (
  <Card
    style={{
      width: 240,
      margin: "auto",
      padding: "1em",
      border: "none",
      textAlign: "center",
    }}
    cover={
      <img
        alt="example"
        style={{
          borderRadius: "50%",
          margin: "auto",
          height: "18em",
          objectFit: "cover",
        }}
        src={Johan}
      />
    }
  >
    <Meta
      title={
        <span
          className="Mulish"
          style={{
            fontSize: 20,
            marginTop: "0rem",
            // color: "#2F3D54",
            fontStyle: "italic",
          }}
        >
          Johan Botha
        </span>
      }
      description={
        <span
          style={{
            marginTop: "0rem",
            color: "black",
          }}
        >
          Director
        </span>
      }
    />
  </Card>
);
const MeetTheTeam2 = () => (
  <Card
    style={{
      width: 240,
      margin: "auto",
      padding: "1em",
      border: "none",
      textAlign: "center",
    }}
    cover={
      <img
        alt="example"
        style={{
          borderRadius: "50%",
          margin: "auto",
          height: "18em",
          objectFit: "cover",
        }}
        src={Justin}
      />
    }
  >
    <Meta
      title={
        <span
          className="Mulish"
          style={{
            fontSize: 20,
            marginTop: "0rem",
            // color: "#2F3D54",
            fontStyle: "italic",
          }}
        >
          Justin Steyn
        </span>
      }
      description={
        <span
          style={{
            marginTop: "0rem",
            color: "black",
          }}
        >
          Director
        </span>
      }
    />
  </Card>
);
const ProductsHeader = () => (
  <div>
    <h1
      className="Mulish"
      style={{
        margin: "auto",
        fontWeight: 500,
        fontSize: window.isPhone ? "25px" : "42px",
      }}
    >
      Our Business Sectors
    </h1>
    {/* <p
        className="Mulish"
        style={{
          fontSize: 18,
          marginTop: "1.5rem",
          color: "black",
        }}
      >
        Our product offerings go way beyond amazing product manufacturing. We
        pride ourselves on supplying as well as maintaining professional
        customer service.
      </p> */}
  </div>
);
const ProductsHeader2 = () => (
  <div>
    <h1
      className="Mulish"
      style={{
        margin: "auto",
        fontWeight: 500,
        fontSize: window.isPhone ? "25px" : "42px",
      }}
    >
      Meet The Team
    </h1>
    <div
      style={{
        paddingTop: "0.5em",
        margin: "auto",
        width: "2em",
        borderBottom: "2px solid black",
      }}
    ></div>
    {/* <p
        className="Mulish"
        style={{
          fontSize: 18,
          marginTop: "1.5rem",
          color: "black",
        }}
      >
        Our product offerings go way beyond amazing product manufacturing. We
        pride ourselves on supplying as well as maintaining professional
        customer service.
      </p> */}
  </div>
);
const ProductsContent = () => (
  <Row style={{ margin: window.isPhone ? "1em" : "3em", marginTop: "2em" }}>
    <Col
      sm={24}
      md={6}
      style={{
        backgroundColor: "#EBEFF3",
        paddingLeft: "3em",
        paddingRight: "3em",
        border: "5px solid white",
      }}
    >
      <Card
        style={{
          backgroundColor: "transparent",
          marginTop: "0.5em",
          marginBottom: "0.5em",
          // padding: "1em",
          borderRadius: "0em",
          border: "none",
          textAlign: "center",
          fontSize: 40,
          color: "#2F3D54",
        }}
        // hoverable
        cover={
          <h1
            style={{
              fontFamily: "Crimson Text",
              fontWeight: 100,
              marginBottom: "0.2em",
              color: "#2F3D54",
              fontStyle: "italic",
              fontSize: "55px",
            }}
          >
            {" "}
            01.{" "}
            <div
              style={{
                width: "1em",
                borderBottom: "1px solid #2F3D54",
                margin: "auto",
                marginTop: "-0.3em",
              }}
            />
          </h1>
        }
      >
        <Meta
          title={
            <span>
              <p
                className="Mulish"
                style={{
                  fontSize: 25,
                  marginTop: "0rem",
                  color: "#2F3D54",
                  lineHeight: "1em",
                  whiteSpace: "break-spaces",
                  fontStyle: "italic",
                }}
              >
                Conveyancing
              </p>
            </span>
          }
          description={
            <span>
              {" "}
              <p
                className="Mulish"
                style={{
                  fontSize: 14,
                  marginTop: "-0.5rem",
                  fontFamily: "Lato",
                  color: "#2F3D54",
                }}
              >
                "CONVEYANCING" describes the legal process whereby a person,
                company, close corporation or trust becomes the registered and
                lawful owner of fixed property and ensures that such ownership
                cannot be challenged. It also encompasses the process of the
                registration of mortgage bonds.
              </p>{" "}
            </span>
          }
        />
        <a
          href="/#contact-us"
          className="Mulish button-hover"
          style={{
            fontWeight: 200,
            fontSize: "16px",
            letterSpacing: "0.2em",
            border: "none",

            color: "#162036",
          }}
        >
          More Info
          <MdOutlineKeyboardArrowRight
            style={{ marginLeft: "0.5em", marginBottom: "-0.2em" }}
          />
        </a>
      </Card>
    </Col>
    <Col
      sm={24}
      md={6}
      style={{
        backgroundColor: "#EBEFF3",
        paddingLeft: "3em",
        paddingRight: "3em",
        border: "5px solid white",
      }}
    >
      <Card
        style={{
          backgroundColor: "transparent",

          marginTop: "0.5em",
          marginBottom: "0.5em",
          // padding: "1em",
          borderRadius: "0em",
          border: "none",
          textAlign: "center",
          fontSize: 40,
          color: "#2F3D54",
        }}
        // hoverable
        cover={
          <h1
            style={{
              fontFamily: "Crimson Text",
              fontWeight: 100,
              marginBottom: "0.2em",
              color: "#2F3D54",
              fontStyle: "italic",
              fontSize: "55px",
            }}
          >
            02.{" "}
            <div
              style={{
                width: "1em",
                borderBottom: "1px solid #2F3D54",
                margin: "auto",
                marginTop: "-0.3em",
              }}
            />
          </h1>
        }
      >
        <Meta
          title={
            <span>
              <p
                className="Mulish"
                style={{
                  fontSize: 25,
                  marginTop: "0rem",
                  color: "#2F3D54",
                  lineHeight: "1em",
                  whiteSpace: "break-spaces",
                  fontStyle: "italic",
                }}
              >
                Debt Collection
              </p>
            </span>
          }
          description={
            <span>
              {" "}
              <p
                className="Mulish"
                style={{
                  fontSize: 14,
                  marginTop: "-0.5rem",
                  fontFamily: "Lato",
                  color: "#2F3D54",
                }}
              >
                Debt collection is the process of pursuing payments of debts
                owed by individuals or businesses. We specialize in all aspects
                of Consumer Debt Recovery. We use a combination of mechanisms,
                depending on each case's unique circumstances, to collect debt
                as fast and cost effective as possible.
              </p>{" "}
            </span>
          }
        />
        <a
          href="/#contact-us"
          className="Mulish button-hover"
          style={{
            fontWeight: 200,
            fontSize: "16px",
            letterSpacing: "0.2em",
            border: "none",

            color: "#162036",
          }}
        >
          More Info
          <CgArrowLongRight
            style={{ marginLeft: "0.5em", marginBottom: "-0.25em" }}
          />
        </a>
      </Card>
    </Col>
    <Col
      sm={24}
      md={6}
      style={{
        backgroundColor: "#EBEFF3",
        paddingLeft: "3em",
        paddingRight: "3em",
        border: "5px solid white",
      }}
    >
      <Card
        style={{
          backgroundColor: "transparent",

          marginTop: "0.5em",
          marginBottom: "0.5em",
          // padding: "1em",
          borderRadius: "0em",
          border: "none",
          textAlign: "center",
          fontSize: 40,
          color: "#2F3D54",
        }}
        // hoverable
        cover={
          <h1
            style={{
              fontFamily: "Crimson Text",
              fontWeight: 100,
              marginBottom: "0.2em",
              color: "#2F3D54",
              fontStyle: "italic",
              fontSize: "55px",
            }}
          >
            {" "}
            03.{" "}
            <div
              style={{
                width: "1em",
                borderBottom: "1px solid #2F3D54",
                margin: "auto",
                marginTop: "-0.3em",
              }}
            />
          </h1>
        }
      >
        <Meta
          title={
            <span>
              <p
                className="Mulish"
                style={{
                  fontSize: 25,
                  marginTop: "0rem",
                  color: "#2F3D54",
                  lineHeight: "1em",
                  whiteSpace: "break-spaces",
                  fontStyle: "italic",
                }}
              >
                Wills & Estates & Trusts
              </p>
            </span>
          }
          description={
            <span>
              {" "}
              <p
                className="Mulish"
                style={{
                  fontSize: 14,
                  marginTop: "-0.5rem",
                  fontFamily: "Lato",
                  color: "#2F3D54",
                }}
              >
                We offer expert management of solvent estates, living trusts and
                testamentary trusts. We will help you draft your will and trust
                deed; store your will safely and act as executor of your estate
                and trustee of your trust.
              </p>{" "}
            </span>
          }
        />
        <a
          href="/#contact-us"
          className="Mulish button-hover"
          style={{
            fontWeight: 200,
            fontSize: "16px",
            letterSpacing: "0.2em",
            border: "none",

            color: "#162036",
          }}
        >
          More Info
          <MdOutlineKeyboardArrowRight
            style={{ marginLeft: "0.5em", marginBottom: "-0.2em" }}
          />
        </a>
      </Card>
    </Col>
    <Col
      sm={24}
      md={6}
      style={{
        backgroundColor: "#EBEFF3",
        paddingLeft: "3em",
        paddingRight: "3em",
        border: "5px solid white",
      }}
    >
      <Card
        style={{
          backgroundColor: "transparent",

          marginTop: "0.5em",
          marginBottom: "0.5em",
          // padding: "1em",
          borderRadius: "0em",
          border: "none",
          textAlign: "center",
          fontSize: 40,
          color: "#2F3D54",
        }}
        // hoverable
        cover={
          <h1
            style={{
              fontFamily: "Crimson Text",
              fontWeight: 100,
              marginBottom: "0.2em",
              color: "#2F3D54",
              fontStyle: "italic",
              fontSize: "55px",
            }}
          >
            {" "}
            04.
            <div
              style={{
                width: "1em",
                borderBottom: "1px solid #2F3D54",
                margin: "auto",
                marginTop: "-0.3em",
              }}
            />
          </h1>
        }
      >
        <Meta
          title={
            <span>
              <p
                className="Mulish"
                style={{
                  fontSize: 25,
                  marginTop: "0rem",
                  color: "#2F3D54",
                  lineHeight: "1em",
                  whiteSpace: "break-spaces",
                  fontStyle: "italic",
                }}
              >
                Litigation
              </p>
            </span>
          }
          description={
            <span>
              {" "}
              <p
                className="Mulish"
                style={{
                  fontSize: 14,
                  marginTop: "-0.5rem",
                  fontFamily: "Lato",
                  color: "#2F3D54",
                }}
              >
                Civil litigation is the process of referring an issue, dispute,
                or any other commercial matter to a court for a judge or
                magistrate to decide and can be complicated. Corporate
                Litigation refers to the action taken when a client gets sued or
                when the client sues. This action relates to numberous aspects
                of our clients' businesses including tax, intellectual property,
                litigation, real estate.
              </p>{" "}
            </span>
          }
        />
        <a
          href="/#contact-us"
          className="Mulish button-hover"
          style={{
            fontWeight: 200,
            fontSize: "16px",
            letterSpacing: "0.2em",
            border: "none",

            color: "#162036",
          }}
        >
          More Info
          <CgArrowLongRight
            style={{ marginLeft: "0.5em", marginBottom: "-0.25em" }}
          />
        </a>
      </Card>
    </Col>
  </Row>
);
const CertHeader = () => (
  <div>
    <h1
      className="Mulish"
      style={{
        margin: "auto",
        fontWeight: 500,
        fontSize: window.isPhone ? "80px" : "150px",
        height: "1em",
        marginTop: "-0.5em",
      }}
    >
      “
    </h1>
    <h1
      className="Mulish"
      style={{
        margin: "auto",
        fontWeight: 500,
        fontSize: window.isPhone ? "25px" : "42px",
        lineHeight: "0.5em",
        marginBottom: "1em",
      }}
    >
      <Fade delay={500} cascade>
        When justice is done, it brings joy to the righteous <br />
        but terror to the evildoers.
      </Fade>
    </h1>
    {/* <p
      className="Mulish"
      style={{
        fontSize: 16,
        marginTop: "1.5rem",
        color: "black",
        fontFamily: "Lato",
      }}
    >
      Japanese Proverb
    </p>{" "} */}
  </div>
);
const CertParagraph = () => (
  <div>
    <p
      className="Mulish"
      style={{
        fontSize: 18,
        marginTop: "1.5rem",
        color: "black",
      }}
    >
      Our company endeavors to keep up to date with the increasing number of
      regulations and need for operational transparency in the industry. We
      pride ourselves in sending out quality controlled work welded only by
      fully certified staff.
    </p>{" "}
  </div>
);
const GalleryHeader = () => (
  <div>
    <img
      className="gallery-banner-img"
      style={{ margin: "auto", textAlign: "center" }}
      src={GalleryHome}
    ></img>{" "}
  </div>
);
const GalleryParagraph = () => (
  <div>
    <img
      className="gallery-banner-img"
      style={{ margin: "auto", textAlign: "center" }}
      src={GalleryHome}
    ></img>{" "}
  </div>
);
const ContactHeader = () => (
  <div>
    <h1
      className="Mulish"
      style={{
        margin: "auto",
        fontWeight: 400,
        fontSize: window.isPhone ? "35px" : "50px",
        fontStyle: "italic",
      }}
    >
      Contact Us
    </h1>
    {/* <p
        className="Mulish"
        style={{
          fontSize: 18,
          marginTop: "1.5rem",
          color: "black",
        }}
      >
        Fill in all fields, hit send and we’ll get in touch with you.
      </p>{" "} */}
  </div>
);
//const ContactForm = () => (
//  <Form
//    onFinish={this.handleSubmit}
//    initialValues={{
//      remember: true,
//    }}
//    name="wrap"
//    labelCol={{ flex: "110px" }}
//    labelAlign="left"
//    labelWrap
//    wrapperCol={{ flex: 1 }}
//    colon={false}
//  >
//    <Row style={{}}>
//      <Col sm={24} md={11}>
//        <Form.Item name="name" rules={[{ required: true }]}>
//          <StyledInput placeholder="Company Name" />
//        </Form.Item>
//      </Col>
//      <Col sm={0} md={2}></Col>
//      <Col sm={24} md={11}>
//        <Form.Item name="email" rules={[{ required: true }]}>
//          <StyledInput placeholder="Company Email" />
//        </Form.Item>
//      </Col>
//      <Col sm={24} md={24}>
//        <Form.Item name="message" rules={[{ required: true }]}>
//          <StyledInput placeholder="Message" />
//        </Form.Item>
//      </Col>
//    </Row>
//    <Form.Item>
//      <Button
//        style={{
//          borderRadius: 50,
//          fontWeight: 500,
//          height: "3em",
//          width: "9em",
//          fontSize: "17px",
//          backgroundColor: "#D63629",
//          border: "black",
//          marginTop: "1em",
//        }}
//        type="primary"
//        htmlType="submit"
//      >
//        Send
//      </Button>
//    </Form.Item>
//  </Form>
//);
class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      successModal: false,
    };
    this.changeValue = this.changeValue.bind(this);
    this.onFinish = this.onFinish.bind(this);
  }
  handleTabClick = key => {
    this.props.history.push(`/${key}`); // < == router router v4
  };
  componentDidMount() {
    document.title = "Geldenhuys Botha Inc.";
    document.documentElement.scrollTop = 0;
  }
  onChange = e => {
    this.setState({
      placement: e.target.value,
    });
  };
  onChange = value => {
    this.setState({
      inputValue: value,
    });
  };
  showModal = () => {
    this.setState({
      visiblePopup: true,
    });
  };
  handleOk = e => {
    console.log(e);
    this.setState({
      visiblePopup: false,
    });
  };

  handleCancel = e => {
    console.log(e);
    this.setState({
      visiblePopup: false,
    });
  };
  handleClickDemo(form) {
    this.setState({
      FormChangeDemo: form,
    });
  }
  handleClickCall(form) {
    this.setState({
      FormChangeCall: form,
    });
  }
  showModalDrone = () => {
    this.setState({
      visiblePopupDrone: true,
    });
  };
  handleOk1 = e => {
    console.log(e);
    this.setState({
      visiblePopupDrone: false,
    });
  };

  handleCancel1 = e => {
    console.log(e);
    this.setState({
      visiblePopupDrone: false,
    });
  };
  handleSubmit = values => {
    this.onFinish(values);
  };
  changeValue = event => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleCloseSuccessModal = () => {
    this.setState({ successModal: false });
    window.location.assign("./");
  };

  onFinish = values => {
    const MSG = {
      to: ["deeds@geldenhuysbotha.co.za"],
      from: "info@geldenhuysbotha.co.za",
      //to: ["ianels182@gmail.com", "monique@moniqs-interiors.com"],
      subject: "New contact request",
      text: " ",
      html: `

      <style type="text/css">
        body, #bodyTable{background - color:white}
        #emailHeader{background - color:white}
        #emailBody{background - color:#FFFFFF; border:1px solid #CCCCCC;}
        #emailFooter{background - color:#E1E1E1;}
      </style>
      <body bgcolor="#E1E1E1" leftmargin="0" marginwidth="0" topmargin="0" marginheight="0" offset="0">
        <center style="background-color:#E1E1E1;">
          <table border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" id="bodyTable" style="table-layout: fixed;max-width:100% !important;width: 100% !important;min-width: 100% !important;">
            <tr>
              <td align="center" valign="top" id="bodyCell">
                <table bgcolor="#E1E1E1" border="0" cellpadding="0" cellspacing="0" width="500" id="emailHeader">
                  <!-- HEADER ROW // -->
                  <tr>
                    <td align="center" valign="top">
                      <!-- CENTERING TABLE // -->
                      <table border="0" cellpadding="0" cellspacing="0" width="100%">
                        <tr>
                          <td align="center" valign="top">
                            <!-- FLEXIBLE CONTAINER // -->
                            <table border="0" cellpadding="10" cellspacing="0" width="500" class="flexibleContainer">
                              <tr>
                                <td valign="top" width="500" class="flexibleContainerCell">

                                  <!-- CONTENT TABLE // -->
                                  <table align="left" border="0" cellpadding="0" cellspacing="0" width="100%">
                                    <tr>

                                      <td align="right" valign="middle" class="flexibleContainerBox">
                                        <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width:100%;">
                                          <tr>
                                            <td align="left" class="textContent">
                                              <!-- CONTENT // -->

                                            </td>
                                          </tr>
                                        </table>
                                      </td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                            </table>
                            <!-- // FLEXIBLE CONTAINER -->
                          </td>
                        </tr>
                      </table>
                      <!-- // CENTERING TABLE -->
                    </td>
                  </tr>
                  <!-- // END -->
                </table>
                <!-- EMAIL BODY // -->
                <table bgcolor="#FFFFFF" border="0" cellpadding="0" cellspacing="0" width="500" id="emailBody">
                  <!-- MODULE ROW // -->
                  <tr>
                    <td align="center" valign="top">
                      <!-- CENTERING TABLE // -->
                      <table border="0" cellpadding="0" cellspacing="0" style="color:#FFFFFF;" bgcolor="#101010">
                        <tr>
                          <td align="center" valign="top">
                            <!-- FLEXIBLE CONTAINER // -->

                            <table border="0" cellpadding="0" cellspacing="0" width="500" class="flexibleContainer">
                              <tr>
                                <td align="center" valign="top" width="500" class="flexibleContainerCell">

                                  <!-- CONTENT TABLE // -->

                                  <table border="0" cellpadding="0" cellspacing="0" width="100%" z-index="100" >
                                    <td align="center" valign="top" width="500" class="flexibleContainerCell  ">
                                      <img alt="" src="https://i.ibb.co/5BmY3qC/main-logo-4ec06f084b29ddd52e07b0ef1b81f7d6-1.png" width="300" height="100" style="display: block; width:195px; max-width: 355px;; min-width: 189px;;border-radius: 8px 8px 8px 8px; font-family: Lato, Helvetica, Arial, sans-serif; color: #9AA31E; font-size: 18px;margin-top: 18px;" border="0">     <tr>
                                        <td align="center" valign="top" class="textContent">
                                          <h1 style="color:white;line-height:100%;font-family:Helvetica,Arial,sans-serif;font-size:35px;font-weight:normal;margin-bottom:20px;text-align:center;">Contact Request</h1>
                                          <h2 style="text-align:center;font-weight:normal;font-family:Helvetica,Arial,sans-serif;font-size:17px;margin-bottom:10px;color: white;line-height:135%;">You have received a new contact request from <br />Geldenhuys Botha Inc. website</h2>
                                        </td>
                                      </tr>
                                      </table>
                                      <!-- // CONTENT TABLE -->
                                    </td>
                                  </tr>
                                </table>
                                <!-- // FLEXIBLE CONTAINER -->
                              </td>
                            </tr>
                          </table>
                          <!-- // CENTERING TABLE -->
                        </td>
                      </tr>
                      <!-- MODULE ROW // -->
                      <tr mc:hideable>
                        <td align="center" valign="top">
                          <!-- CENTERING TABLE // -->
                          <table border="0" cellpadding="0" cellspacing="0" width="100%" height="300" >
                            <tr>
                              <td align="center" valign="top">
                                <!-- FLEXIBLE CONTAINER // -->
                                <table border="0" cellpadding="0" cellspacing="0" width="300" class="flexibleContainer">
                                  <tr>
                                    <td valign="top" width="500" class="flexibleContainerCell">
                                      <!-- CONTENT TABLE // -->
                                      <table p align="left" border="0" cellpadding="0" cellspacing="0" width="120%">
                                        <tr>
                                          <td align="center" valign="top" class="flexibleContainerBox">
                                            <table border="0" cellspacing="0" cellpadding="0" >

                                              <tr style="font-family: sans-serif; font-size: 17px; letter-spacing: 1px; color:black">
                                                <td class="role"><br /><br /><br /><br /><br />
                                                  <strong> Name </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.name}<br />
                                                  <strong> Name </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.surname}<br />
                                                  <strong> Email </strong>&nbsp;&nbsp; :                     &nbsp;&nbsp;${values.email}<br />
                                                  <strong> Message </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.message}
                                                  <br /><br /><br />
                                                </td>
                                              </tr>
                                              <table>
                                              </td>
                                            </tr>
                                          </table>
                                          <!-- // CONTENT TABLE -->

                                        </td>
                                      </tr>
                                    </table>
                                    <!-- // FLEXIBLE CONTAINER -->
                                  </td>
                                </tr>
                              </table>
                              <!-- // CENTERING TABLE -->
                            </td>
                          </tr>
                          <!-- // MODULE ROW -->


                          <!-- MODULE ROW // -->

                          <table border="0" cellpadding="0" cellspacing="0" width="100%" style="color:#FFFFFF;" bgcolor="#101010" style="text-align:center;font-weight:normal;font-family:Helvetica,Arial,sans-serif;font-size:13px;margin-bottom:-80px;color: lightgray;line-height:135%;">
                            <tr>
                              <td align="center" valign="top">

                                <table border="0" cellpadding="0" cellspacing="0" width="500" class="flexibleContainer">
                                  <tr>
                                    <td align="center" valign="top" width="500" class="flexibleContainerCell">


                                      <table border="0" cellpadding="30" cellspacing="0" width="100%">


                                        <tr>
                                          <td align="center" valign="top" class="textContent">

                                            <h2 style="text-align:center;font-weight:normal;font-family:Helvetica,Arial,sans-serif;font-size:13px;margin-bottom:0px;color: grey;line-height:135%;">2023@Geldenhuys Botha Inc.</h2>

                                          </td>
                                        </tr>
                                      </table>
                                      <!-- // CONTENT TABLE -->

                                    </td>
                                  </tr>
                                </table>
                                <!-- // FLEXIBLE CONTAINER -->
                              </td>
                            </tr>
                          </table>
                          `,
    };
    fetch("https://www.vcsappcloud.com/emailclient/sendmail", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify(MSG),
    }).then(async res => {
      if (!res.ok) {
        console.log("handle error");
      }
      console.log("handle success");
      this.setState({
        successModal: true,
        submitDisabled: true,
        name: "",
        email: "",
        surname: "",
        message: "",
      });
    });
  };

  render() {
    return (
      <div>
        <img
          className="eifeil-img"
          src={Eifel}
          style={{
            position: "absolute",
            top: 0,
            left: 100,
            opacity: 0.8,
            zIndex: -1,
            width: "45vw",
          }}
        ></img>
        <img
          className="eifeil-img-mobile"
          src={EifelMobile}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            opacity: 0.8,
            zIndex: -1,
            width: "100vw",
          }}
        ></img>
        <Row style={{ maxWidth: "90em", margin: "auto", paddingTop: "6em" }}>
          <Col sm={24} md={24} className="home-col">
            <Title />
          </Col>
        </Row>
        <Row
          style={{
            maxWidth: "110em",
            margin: "auto",
            height: window.isPhone ? "28em" : "40em",
          }}
        >
          <Col
            sm={24}
            md={24}
            style={{ position: "absolute", top: 0, zIndex: -1 }}
          >
            <img
              className="home-img"
              style={{
                margin: "auto",
                marginTop: "4em",
                textAlign: "center",
                width: "100vw",
                maxWidth: "110em",
              }}
              src={HomeImage}
            ></img>{" "}
          </Col>
        </Row>
        <Row
          className="about-row"
          style={{ maxWidth: "90em", margin: "auto", marginTop: "7em" }}
        >
          {" "}
          <Col sm={24} md={12}></Col>
          <Col sm={24} md={12}>
            <Fade cascade right>
              <Col
                sm={24}
                md={23}
                style={{ marginTop: window.isPhone ? "-7em" : "-17vh" }}
              >
                <AboutParagraph />
              </Col>
            </Fade>
          </Col>
        </Row>
        <Row
          style={{
            maxWidth: "110em",
            margin: "auto",
            textAlign: "center",
            marginTop: "4em",
          }}
        >
          <Col sm={24} md={24} style={{ width: "100vw" }}>
            <ProductsHeader />
          </Col>
        </Row>{" "}
        <ProductsContent />
        <Row
          className="about-row-cert"
          style={{
            maxWidth: "70em",
            margin: "auto",
            textAlign: "center",
            marginTop: "3em",
          }}
        >
          <Col sm={24} md={24} style={{ width: "100vw" }}>
            <CertHeader />
          </Col>
          {/* <Col sm={24} md={12}>
            <CertParagraph />
          </Col> */}
        </Row>
        <Row
          style={{
            margin: "auto",
            backgroundImage: window.isPhone
              ? `url(${GalleryHomeBackMobile})`
              : `url(${GalleryHomeBack})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            // borderRadius: "2em",
            padding: window.isPhone ? "6em" : "10em",
            border: "4px solid white",
            backgroundColor: "transparent",
          }}
        >
          <Col sm={24} md={14}>
            {/* <GalleryHeader /> */}
          </Col>

          {/* <Col
              className="gallery-paragraph"
              sm={24}
              md={10}
              style={{ padding: "2em" }}
            >
              <GalleryParagraph />
            </Col> */}
        </Row>
        <Row
          style={{
            maxWidth: "70em",
            margin: "auto",
            textAlign: "center",
            marginTop: "4em",
          }}
        >
          <Col sm={24} md={24} style={{ width: "100vw" }}>
            <ProductsHeader2 />
          </Col>
        </Row>{" "}
        <Row
          style={{
            margin: "auto",
            marginTop: "3em",
            // borderRadius: "2em",
            // padding: window.isPhone ? "6em" : "10em",
            maxWidth: "50em",
            backgroundColor: "transparent",
          }}
        >
          <Col sm={24} md={12} style={{ margin: "auto" }}>
            <MeetTheTeam1 />
          </Col>
          <Col sm={24} md={12} style={{ margin: "auto" }} id="contact-us">
            <MeetTheTeam2 />
          </Col>
        </Row>
        <Row
          style={{
            maxWidth: "30em",
            margin: "auto",
            textAlign: "cleft",
            marginTop: window.isPhone ? "1em" : "3em",
            padding: window.isPhone ? "2em" : "",
            paddingBottom: 0,
          }}
        >
          <Col sm={24} md={24}>
            <ContactHeader />
          </Col>
        </Row>
        <Row
          style={{
            maxWidth: window.isPhone ? "25em" : "30em",
            margin: "auto",
            textAlign: "center",
            marginTop: "1em",
          }}
        >
          <Col sm={24} md={24} style={{ padding: window.isPhone ? "1em" : "" }}>
            <Form
              onFinish={this.handleSubmit}
              initialValues={{
                remember: true,
              }}
              name="wrap"
              layout="vertical"
              colon={false}
            >
              <Row style={{}}>
                <Col sm={24} md={22}>
                  <Form.Item
                    label="First Name"
                    name="name"
                    rules={[{ required: false }]}
                  >
                    <StyledInput
                      style={{ width: window.isPhone ? "77vw" : "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col sm={24} md={20}>
                  <Form.Item
                    label="Last Name"
                    name="surname"
                    rules={[{ required: false }]}
                  >
                    <StyledInput
                      style={{ width: window.isPhone ? "77vw" : "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col sm={24} md={24}>
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[{ required: true }]}
                  >
                    <StyledInput
                      style={{ width: window.isPhone ? "77vw" : "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col sm={24} md={24}>
                  <Form.Item
                    label="Write a message"
                    name="message"
                    rules={[{ required: false }]}
                  >
                    <StyledInput
                      style={{ width: window.isPhone ? "77vw" : "100%" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item>
                <Button
                  id="about-us"
                  style={{
                    borderRadius: 50,
                    fontWeight: 500,
                    height: "2.5em",
                    width: "8em",
                    fontSize: "15px",
                    backgroundColor: "#1B181A",
                    border: "black",
                    marginTop: "1em",
                    float: "left",
                  }}
                  type="primary"
                  htmlType="submit"
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Col>
          {/* <img
            className="contact-image"
            style={{
              marginTop: "-3em",
              left: 0,
              zIndex: -1,
              position: "absolute",
            }}
            src={WingLeft}
          ></img>
          <img
            className="hori-line"
            style={{
              marginTop: "-15em",
              right: 0,
              zIndex: -1,
              position: "absolute",
            }}
            src={WingRight}
          ></img>{" "} */}
        </Row>{" "}
        <div style={{ width: "100vw", backgroundColor: "#EBEFF3" }}>
          <Row
            className="about-row"
            style={{ maxWidth: "90em", margin: "auto", marginTop: "3em" }}
          >
            <Col sm={24} md={14}>
              <AboutHeader1 />
            </Col>
            <Col sm={24} md={10} style={{}}>
              <AboutParagraph1 />
            </Col>
          </Row>
        </div>
        {/* <Row
          className="about-row"
          style={{ maxWidth: "90em", margin: "auto", marginTop: "0em" }}
        >
          <Col sm={12} md={12}>
            <img
              className="products-img-2"
              style={{ margin: "auto", width: "100%", textAlign: "center", objectFit: "cover" }}
              src={Justin}
            ></img>
          </Col>
          <Col sm={12} md={12} style={{}}>
            <img
              className="products-img-2"
              style={{ margin: "auto", width: "100%", textAlign: "center", objectFit: "cover" }}
              src={Johan}
            ></img>
          </Col>
        </Row> */}
        <SuccessModal
          open={this.state.successModal}
          onClose={this.handleCloseSuccessModal}
          center
        >
          <img src={LogoModal} alt="Logo" />
          <h3
            style={{
              fontFamily: "Crimson Text",
              marginTop: "1em",
              color: "white",
              textAlign: "center",
            }}
          >
            Thank you for contacting
            <span>
              <br />
              Geldenhuys Botha Inc.
            </span>{" "}
            <br />
            <span style={{ color: "white" }}>
              We will be in touch shortly.
            </span>{" "}
            <br />
          </h3>{" "}
          <br />
          <Button
            onClick={this.handleCloseSuccessModal}
            style={{
              margin: "auto",
              height: "2.9em",
              fontFamily: "Nunito",
              fontSize: "1rem",
              fontWeight: 500,
              borderRadius: "5em",
              backgroundColor: "white",
              color: "black",
              border: "none",
              marginBottom: "1em",
              paddingLeft: "3em",
              paddingRight: "3em",
              marginRight: "0em",
              textShadow: "none",
            }}
            type="primary"
          >
            Close
          </Button>{" "}
        </SuccessModal>
      </div>
    );
  }
}

export default Home;
