import React, { useState } from "react";
import ReactDOM from "react-dom";
import { PageHeader, Button, Menu, Dropdown } from "antd";
import { Link } from "react-router-dom";
import { slide as Burger, SubMenu, Item } from "burger-menu";
import "burger-menu/lib/index.css";
import { DownOutlined } from "@ant-design/icons";
import MainLogo from "../static/Home/main-logo.svg";
import "antd/dist/antd.css";

const BurgerMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <div id="nav-toggle" onClick={() => setIsOpen(!isOpen)}>
        <span></span>
      </div>
      <Burger
        left
        className="burger-menu"
        isOpen={isOpen}
        selectedKey={"entry"}
        onClose={() => setIsOpen(false)}
      >
        <a href="/">
          <Item itemKey={"a"} text={"Home"} onClick={() => setIsOpen(false)}></Item>
        </a>
        <a href="/#about-us">
          {" "}
          <Item itemKey={"b"} text={"About"} onClick={() => setIsOpen(false)}></Item>
        </a>
        <a href="#services">
          {" "}
          <Item itemKey={"c"} text={"Services"} onClick={() => setIsOpen(false)}></Item>
        </a>
        <a href="/#contact-us">
          {" "}
          <Item itemKey={"d"} text={"Contact Us"} onClick={() => setIsOpen(false)}></Item>
        </a>
        {/*<SubMenu title="Union Management">
          <Item itemKey={"notice"} text={"Announcement"}></Item>
          <Item itemKey={"union"} text={"Union Inquiries"}></Item>
          <Item itemKey={"entry"} text={"Entry information"}></Item>
        </SubMenu>*/}
      </Burger>
    </>
  );
};
const MainHeader = () => (
  <div id="Header" className="site-page-header-ghost-wrapper" style={{ backgroundColor: "#1B181A", }}>
    <PageHeader
      style={{
        backgroundColor: "transparent",
        maxWidth: "90em",
        width: "90vw",
        margin: "auto",
        padding: 0,
      }}
      ghost={false}
      //  onBack={() => window.history.back()}
      title={
        <div>
          <Link to="/">
            <img
              className="header-logo"
              // style={{ width: "8em" }}
              src={MainLogo}
            ></img>{" "}
          </Link>
          <div class="main">
            <div class="circle"></div>
          </div>
        </div>
      }
      //  subTitle="This is a subtitle"
      extra={[
        <BurgerMenu />,
        <a href="/" className="mobile-display">
          <Button
            style={{
              backgroundColor: "#1B181A",
              color: "white",
              marginTop: "1.9em",
              padding: "1em",
              paddingTop: 0,
              border: "none",
              fontWeight: 500,
              boxShadow: "none",
              fontSize: "17px",
            }}
          >
            Home
          </Button>
        </a>,
        <a href="/#about-us" className="mobile-display">
          <span
            style={{
              backgroundColor: "#1B181A",
              padding: "1em",
              paddingTop: 0,
              color: "white",
              marginTop: "1.6em",
              border: "none",
              fontWeight: 500,
              boxShadow: "none",
              fontSize: "17px",
            }}
          >
            About
          </span>
        </a>,
        //<Button
        //  style={{
        //    color: "white",
        //    marginTop: "1.6em",
        //    border: "none",
        //    boxShadow: "none",
        //    fontWeight: 500,
        //    fontSize: "17px",
        //  }}
        //>
        //  <Dropdown overlay={menu}>
        //    <a
        //      className="ant-dropdown-link"
        //      onClick={(e) => e.preventDefault()}
        //    >
        //      Products <DownOutlined />
        //    </a>
        //  </Dropdown>
        //</Button>,
        <a href="/#services" className="mobile-display">
          <span
            style={{
              backgroundColor: "#1B181A",
              padding: "1em",
              color: "white",
              marginTop: "1.6em",
              border: "none",
              boxShadow: "none",
              fontWeight: 500,
              fontSize: "17px",
            }}
          >
            Services
          </span>
        </a>,
        <a href="/#contact-us" className="mobile-display">
          <span
            style={{
              backgroundColor: "#1B181A",
              padding: "1em",
              paddingTop: 0,
              color: "white",
              marginTop: "1.6em",
              border: "none",
              boxShadow: "none",
              fontWeight: 500,
              fontSize: "17px",
            }}
            type="primary"
          >
            Contact us
          </span>
        </a>,
      ]}
    >
      {/*<Descriptions size="small" column={3}>
         <Descriptions.Item label="Created">Lili Qu</Descriptions.Item>
         <Descriptions.Item label="Association">
           <a>421421</a>
         </Descriptions.Item>
         <Descriptions.Item label="Creation Time">2017-01-10</Descriptions.Item>
         <Descriptions.Item label="Effective Time">2017-10-10</Descriptions.Item>
         <Descriptions.Item label="Remarks">
           Gonghu Road, Xihu District, Hangzhou, Zhejiang, China
         </Descriptions.Item>
       </Descriptions>*/}
    </PageHeader>
  </div>
);

const Header = () => (
  <header className="Homepage">
    <MainHeader />
  </header>
);

export default Header;
