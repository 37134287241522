import React, { Component } from "react";
import { Layout, Icon, Row, Col, Form, Input, Button, Modal } from "antd";
import PriceBar from "../static/Home/price-bar.png";
import LogoModal from "../static/Home/main-logo.svg";
import Facebook from "../static/Home/facebook.svg";
import Insta from "../static/Home/insta.svg";
import Whatsapp from "../static/Home/whatsapp.svg";
import FooterBackBlack from "../static/Home/footer-back-black.png";
import FooterLogo from "../static/Home/footer-logo.svg";
import FooterLogoMobile from "../static/Home/footer-logo-mobile.svg";
import { Modal as SuccessModal } from "react-responsive-modal";
import { Link } from "react-router-dom";
import styled from "styled-components";

if (window.innerWidth <= 440 || window.innerHeight <= 440) {
  window.isPhone = true;
} else {
  window.isPhone = false;
}
const StyledModal = styled(Modal)`
  width: 70vw !important;
  margin-top: -5em;
  .ant-modal {
    width: 80vw !important;
  }
  .ant-modal-close-x {
    position: absolute;
    top: 0.5em;
    right: 0.5em;
    background-color: black;
    border-radius: 3em;
    display: block;
    width: 40px !important;
    height: 40px !important;
    font-size: 20px !important;
    color: white !important;
    font-style: normal;
    line-height: 38px !important;
    text-align: center;
    text-transform: none;
    text-rendering: auto;
  }
  .ant-modal-mask {
    background-color: white !important;
  }
  .ant-modal-footer {
    display: none;
  }
  .react-responsive-modal-modal {
    background-color: transparent !important;
  }
  .ant-modal-content {
    margin: auto;

    background-color: white !important;
  }
  .ant-modal-body {
    margin: auto;
    width: 50vw;
    overflow: hidden;
    padding-left: 0em;
    background-color: transparent !important;
  }
  .react-responsive-modal-closeButton {
    display: none;
  }
  .react-responsive-modal-closeButton {
    border: none !important;
  }
  .ant-modal-centered .ant-modal {
    width: 99vw !important;
    background-color: transparent !important;
  }
`;
class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      placement: "left",
      inputValue: 1,
      FormChange: false,
      visible: false,
      visiblePopup: false,
      visiblePopupDrone: false,
      open: false,
      successModal: false,
      successModalNewsletter: false,
      tabPosition: "left",
    };
    this.changeValue = this.changeValue.bind(this);
    this.onFinish = this.onFinish.bind(this);
  }
  handleTabClick = key => {
    this.props.history.push(`/${key}`); // < == router router v4
  };
  componentDidMount() {
    document.title = "Geldenhuys Botha Inc.";
    document.documentElement.scrollTop = 0;
  }
  onChange = e => {
    this.setState({
      placement: e.target.value,
    });
  };
  onChange = value => {
    this.setState({
      inputValue: value,
    });
  };
  showModal = () => {
    this.setState({
      visiblePopup: true,
    });
  };
  handleOk = e => {
    console.log(e);
    this.setState({
      visiblePopup: false,
    });
  };

  handleCancel = e => {
    console.log(e);
    this.setState({
      visiblePopup: false,
    });
  };
  handleClickDemo(form) {
    this.setState({
      FormChangeDemo: form,
    });
  }
  handleClickCall(form) {
    this.setState({
      FormChangeCall: form,
    });
  }
  showModalDrone = () => {
    this.setState({
      visiblePopupDrone: true,
    });
  };
  handleOk1 = e => {
    console.log(e);
    this.setState({
      visiblePopupDrone: false,
    });
  };

  handleCancel1 = e => {
    console.log(e);
    this.setState({
      visiblePopupDrone: false,
    });
  };
  handleSubmit = values => {
    this.onFinish(values);
  };
  changeValue = event => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  onOpenModal = () => {
    this.setState({ open: true });
  };
  onCloseModal = () => {
    this.setState({ open: false });
  };
  handleCloseSuccessModal = () => {
    this.setState({ successModal: false });
    window.location.assign("./");
  };

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };
  onClose = () => {
    this.setState({
      visible: false,
    });
  };
  showModal1 = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = e => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleCancel = e => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };
  onFinish = values => {
    const MSG = {
      to: ["ianels182@gmail.com"],
      from: "info@ewb.co.za",
      //to: ["ianels182@gmail.com", "monique@moniqs-interiors.com"],
      subject: "New contact request",
      text: " ",
      html: `
    
      <style type="text/css">  
      body, #bodyTable{background-color:white}
      #emailHeader{background-color:white}
      #emailBody{background-color:#FFFFFF; border:1px solid #CCCCCC;}
      #emailFooter{background-color:#E1E1E1;}      
</style>
<body bgcolor="#E1E1E1" leftmargin="0" marginwidth="0" topmargin="0" marginheight="0" offset="0">
<center style="background-color:#E1E1E1;">
<table border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" id="bodyTable" style="table-layout: fixed;max-width:100% !important;width: 100% !important;min-width: 100% !important;">
  <tr>
    <td align="center" valign="top" id="bodyCell">      
      <table bgcolor="#E1E1E1" border="0" cellpadding="0" cellspacing="0" width="500" id="emailHeader">
        <!-- HEADER ROW // -->
        <tr>
          <td align="center" valign="top">
            <!-- CENTERING TABLE // -->
            <table border="0" cellpadding="0" cellspacing="0" width="100%">
              <tr>
                <td align="center" valign="top">
                  <!-- FLEXIBLE CONTAINER // -->
                  <table border="0" cellpadding="10" cellspacing="0" width="500" class="flexibleContainer">
                    <tr>
                      <td valign="top" width="500" class="flexibleContainerCell">

                        <!-- CONTENT TABLE // -->
                        <table align="left" border="0" cellpadding="0" cellspacing="0" width="100%">
                          <tr>
                       
                            <td align="right" valign="middle" class="flexibleContainerBox">
                              <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width:100%;">
                                <tr>
                                  <td align="left" class="textContent">
                                    <!-- CONTENT // -->
                                   
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
            <!-- // CENTERING TABLE -->
          </td>
        </tr>
        <!-- // END -->
      </table>     
      <!-- EMAIL BODY // -->   
      <table bgcolor="#FFFFFF" border="0" cellpadding="0" cellspacing="0" width="500" id="emailBody">
        <!-- MODULE ROW // -->
        <tr>
          <td align="center" valign="top">
            <!-- CENTERING TABLE // -->
            <table border="0" cellpadding="0" cellspacing="0"   style="color:#FFFFFF;" bgcolor="black">
              <tr>
                <td align="center" valign="top">
                  <!-- FLEXIBLE CONTAINER // -->
                 
                  <table border="0" cellpadding="0" cellspacing="0" width="500"   class="flexibleContainer">
                    <tr>
                      <td align="center" valign="top" width="500" class="flexibleContainerCell">

                        <!-- CONTENT TABLE // -->
                      
                        <table border="0" cellpadding="0" cellspacing="0" width="100%" z-index="100" >
                               <td align="center" valign="top" width="500" class="flexibleContainerCell  ">   
                                <img alt="" src="https://i.ibb.co/Yhg1gg8/logo.png" width="300" height="100" style="display: block; width:195px; max-width: 355px;; min-width: 355px;;border-radius: 8px 8px 8px 8px; font-family: Lato, Helvetica, Arial, sans-serif; color: #9AA31E; font-size: 18px;margin-top: 18px;" border="0">     <tr>
                            <td align="center" valign="top" class="textContent">
                              <h1 style="color:black;line-height:100%;font-family:Helvetica,Arial,sans-serif;font-size:35px;font-weight:normal;margin-bottom:20px;text-align:center;">Contact Request</h1>
                              <h2 style="text-align:center;font-weight:normal;font-family:Helvetica,Arial,sans-serif;font-size:17px;margin-bottom:10px;color: black;line-height:135%;">You have received a new contact request from <br /> EWB Equipment website</h2>
                            </td>
                          </tr>
                        </table>
                        <!-- // CONTENT TABLE -->
                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
            <!-- // CENTERING TABLE -->
          </td>
        </tr>
        <!-- MODULE ROW // -->
        <tr mc:hideable>
          <td align="center" valign="top">
            <!-- CENTERING TABLE // -->
            <table border="0" cellpadding="0" cellspacing="0" width="100%" height="300" >
              <tr>
                <td align="center" valign="top">
                  <!-- FLEXIBLE CONTAINER // -->
                  <table border="0" cellpadding="0" cellspacing="0" width="300" class="flexibleContainer">
                    <tr>
                      <td valign="top" width="500" class="flexibleContainerCell">
                        <!-- CONTENT TABLE // -->
                        <table p align="left" border="0" cellpadding="0" cellspacing="0" width="120%">
                          <tr>
                            <td align="center" valign="top" class="flexibleContainerBox">
                               <table border="0" cellspacing="0" cellpadding="0" >
            
                        <tr style="font-family: sans-serif; font-size: 17px; letter-spacing: 1px; color:black">
                          <td class="role"><br /><br /><br /><br /><br />
                          <strong> Name </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.name}<br />
                           
                               <strong> Message </strong>&nbsp;&nbsp; :                     &nbsp;&nbsp;${values.message}<br />
                               <strong> Email </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.email}
                             <br /><br /><br />
                          </td>
                        </tr>
                        <table>
                            </td>
                          </tr>
                        </table>
                        <!-- // CONTENT TABLE -->

                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
            <!-- // CENTERING TABLE -->
          </td>
        </tr>
        <!-- // MODULE ROW -->


        <!-- MODULE ROW // -->
       
<table border="0" cellpadding="0" cellspacing="0" width="100%"  style="color:#FFFFFF;" bgcolor="black" style="text-align:center;font-weight:normal;font-family:Helvetica,Arial,sans-serif;font-size:13px;margin-bottom:-80px;color: lightgray;line-height:135%;">
              <tr>
                <td align="center" valign="top">
                
                  <table border="0" cellpadding="0" cellspacing="0" width="500"   class="flexibleContainer">
                    <tr>
                      <td align="center" valign="top" width="500" class="flexibleContainerCell">

                    
                        <table border="0" cellpadding="30" cellspacing="0" width="100%">
                      
                    
                          <tr>
                            <td align="center" valign="top" class="textContent">
        
                              <h2 style="text-align:center;font-weight:normal;font-family:Helvetica,Arial,sans-serif;font-size:13px;margin-bottom:0px;color: grey;line-height:135%;">2021 Moniq's</h2>
               
                            </td>
                          </tr>
                        </table>
                        <!-- // CONTENT TABLE -->

                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
     `,
    };
    fetch("https://www.vcsappcloud.com/emailclient/sendmail", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify(MSG),
    }).then(async res => {
      if (!res.ok) {
        console.log("handle error");
      }
      console.log("handle success");
      this.setState({
        successModal: true,
        submitDisabled: true,
        name: "",
        message: "",
        email: "",
      });
    });
  };

  render() {
    return (
      <div
        style={{
          backgroundColor: "black",
          padding: "1em",
          marginTop: window.isPhone ? "2em" : "6.5em",
          // backgroundImage: `url(${FooterBackBlack})`,
          // backgroundPosition: "center",
          // backgroundSize: "cover",
          // backgroundRepeat: "no-repeat",
        }}
      >
        <div
          className="footer-div"
          id="footer"
          style={{
            margin: "auto",
            marginTop: "0.5em",
            backgroundColor: "transparent",
            maxWidth: "100em",
            zIndex: 999,
            paddingTop: "0em",
            height: "24em",
            borderTop: "0px solid lightgrey",
            borderRadius: "0em 0em 0em 0em",
          }}
        >
          <Row
            justify="center"
            className="footer"
            style={{
              margin: "auto",
              paddingTop: "1.9em",
              zIndex: 999,
              maxWidth: "80em",
              height: "16.5em",
              textAlign: "left",
            }}
          >
            <Col sm={24} md={12} className="footer-logo">
              <img
                src={window.isPhone ? FooterLogoMobile : FooterLogo}
                alt="Logo"
                style={{
                  marginTop: window.isPhone ? "" : "5em",
                  boxShadow: "none",
                  // width: "20em",
                }}
              />{" "}
              <p
                className="Mulish-footer desktop-view"
                style={{
                  fontSize: 12,
                  marginTop: "1.5rem",
                  paddingRight: "3em",
                  color: "white",
                  fontFamily: "Lato",
                }}
              >
                © 2023 by Geldenhuys Botha Inc. Proudly Created by{" "}
                <a
                  href="https://vermelion.net/"
                  targer="_blank"
                  style={{ color: "white", textDecoration: "underline" }}
                >
                  Vermelion
                </a>
              </p>{" "}
            </Col>{" "}
            <Col
              sm={0}
              md={4}
              style={{
                textAlign: "left",
                marginTop: window.isPhone ? "" : "3em",
              }}
              className="footer-links-company"
            ></Col>
            <Col
              sm={0}
              md={2}
              style={{
                textAlign: "left",
                marginTop: window.isPhone ? "" : "3em",
              }}
              className="footer-links-company"
            >
              <br />
            </Col>
            <Col
              sm={24}
              md={6}
              style={{
                textAlign: "left",
                marginTop: window.isPhone ? "" : "1em",
              }}
              className="footer-links-company"
            >
              <h1
                style={{
                  fontSize: 13,
                  fontFamily: "Mulish",
                  lineHeight: "1.2em",
                  color: "white",
                }}
              >
                {" "}
                <br />
                <br />
                Address:
                <br />
                180 Vinko Street
                <br />
                Sinoville
                <br />
                Pretoria
                <br />
                <br />
                Conveyancing: deeds@geldenhuysbotha.co.za
                <br />
                Litigation: litigasie2@geldenhuysbotha.co.za
                <br />
                <br />
                Tel: (012) 543 3235
                <br />
                Email: deeds@geldenhuysbotha.co.za
              </h1>
            </Col>
            <p
              className="Mulish-footer mobile-view footer-links-company"
              style={{
                fontSize: 12,
                marginTop: "1.5rem",
                paddingRight: "3em",
                color: "white",
                fontFamily: "Lato",
                textAlign: "left",
              }}
            >
              © 2023 by Geldenhuys Botha Inc. <br />
              Proudly Created by{" "}
              <a
                href="https://vermelion.net/"
                targer="_blank"
                style={{ color: "white", textDecoration: "underline" }}
              >
                Vermelion
              </a>
            </p>{" "}
          </Row>
        </div>
        {/* <Row style={{ margin: "auto", textAlign: "center", display: "block" }}>
          <p
            style={{
              fontSize: "11px",
              fontWeight: 200,
              fontFamily: "Mulish",
              lineHeight: "0em",
              marginTop: "1.5em",
              fontFamily: "Mulish",
              color: "lightgrey",
            }}
          >
            © 2023 — VENTUREPOINT
          </p>{" "}
        </Row> */}
      </div>
    );
  }
}
export default Footer;
